import { defineMessages } from 'react-intl'

const translations = defineMessages({
  programmingLanguages: {
    id: 'skills.programmingLanguages',
    defaultMessage: 'Programming languages'
  },
  frameworks: {
    id: 'skills.frameworks',
    defaultMessage: 'Frameworks'
  },
  databases: {
    id: 'skills.databases',
    defaultMessage: 'Databases'
  },
  developmentTools: {
    id: 'skills.developmentTools',
    defaultMessage: 'Development Tools'
  },
  libraries: {
    id: 'skills.libraries',
    defaultMessage: 'Libraries'
  },
  others: {
    id: 'skills.others',
    defaultMessage: 'Others'
  }
});

export default translations;






