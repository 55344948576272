import cv_en from './cv_en.json'
import cv_es from './cv_es.json'

const config = {
  "googleAnalytics": "UA-151885864-1",
  "website": {
    "en": {
      "title": "My CV - Nahuel Verdugo Revigliono",
      "description": "Just a landing page to my CV."
    },
    "es": {
      "title": "Mi CV - Nahuel Verdugo Revigliono",
      "description": "Solo una página estática con mi CV."
    }
  },
  "cvs": {
    "en": cv_en,
    "es": cv_es
  }
};

export default config;